(function ($) {
    // Show alert at top of screen if cms preview mode is enabled for any page
    function showCookieAlert() {
        if (!$.cookie || $('#cmsPreviewAlert').length)
            return;

        var cmsCookie = $.cookie('evoContent');
        if (cmsCookie) {
            var $alert = $('<div id="cmsPreviewAlert" class="alert alert-warning"><strong>Preview Mode Enabled</strong>&nbsp;You are in CMS preview mode.  <span class="btn btn-default btn-xs">click here</span> to return to normal mode.</div>');
            $alert.find('.btn').on('click', function () {
                var thisDomain = location.href.replace(/(https?\:\/\/)*(www\.)*/, '').split('/')[0];
                if ($.removeCookie)
                    $.removeCookie('evoContent', { domain: thisDomain, path: '/' });
                else
                    $.cookie('evoContent', null, { domain: thisDomain, path: '/' });

                location.reload();
            });
            $('#wrapper').prepend($alert);
        }
    }

    $(showCookieAlert);
})(jQuery);