/// <reference path="../libs/resize-sensor.js" />
/// <reference path="../libs/jquery.sticky-kit.js" />

(function ($) {
    function loadSticky() {
        var $stickyElement = $('.js-sticky-kit'),
            isSticky = false;

        //test against the window width and whether or not sticky-kit has been set up.
        function detectSticky() {
            if (evo.$.window.width() >= evo.mq.screenSmMin) {
                if (isSticky === false) {
                    $stickyElement.each(function () {
                        var $this = $(this),
                            stickyOffset = parseInt($this.attr('data-offset-top'));

                        if (stickyOffset) {
                            $this.stick_in_parent({
                                offset_top: stickyOffset
                            });
                        } else {
                            $this.stick_in_parent();
                        }
                    });
                    isSticky = true;
                }
            }
            else {
                //remove it for mobile
                if (isSticky === true) {
                    $stickyElement.trigger('sticky_kit:detach');
                    isSticky = false;
                }
            }
        };

        detectSticky();
        //throttle the resize event
        var resizeTimer;
        evo.$.window.on('resize', function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(detectSticky, 250);
        });
    };

    //dom ready
    $(function () {
        //detect loading of Power Reviews and recalculate stickyness
        new ResizeSensor($('#wrapper'), function () {
            evo.$.body.trigger("sticky_kit:recalc");
        });
        loadSticky();
    });

    // reattach sticky on results-page refresh
    if (!Modernizr.touch) {
        $('#results-page').on('refresh.results', loadSticky);
    }
})(jQuery);