(function ($) {
    $.fn['testimonials'] = function () {
        $(this).not('.loaded').addClass('loaded').each(function () {
            var $this = $(this),
                $currentSlide = $this.find('.testimonial-slide:first-child').addClass('active');

            function move(left) {
                var $next = $currentSlide[left ? 'prev' : 'next']('.testimonial-slide');

                if (!$next.length) {
                    $next = $this.find('.testimonial-slide:' + (left ? 'last' : 'first') + '-child');
                };
                $next.add($currentSlide).toggleClass('active');
                $currentSlide = $next;
            };

            $this.find('.prev-arrow').on('click', move.bind(null, true));
            $this.find('.next-arrow').on('click', move.bind(null, false));
        })
    };

    $('.testimonial-wrapper').testimonials();
})(jQuery);