(function ($) {
    $('.js-hero-video').each(function(){
        var $video = $(this),
            $videoSrc = $video.find('source'),
            portrait = $video.data('portrait'),
            landscape = $video.data('landscape');

        var togglePlay = function () {
            $video.next(".js-playpause").on('click', function () {
                var $this = $(this),
                    $video = $this.prev('.js-hero-video');

                if ($this.hasClass('playing')) {
                    // pause video
                    $video.trigger('pause');
                    $this.removeClass('playing');
                    $this.find('.visually-hidden').text('Play Video');
                }
                else {
                    // play video
                    $video.trigger('play');
                    $this.addClass('playing');
                    $this.find('.visually-hidden').text('Pause Video');
                }
            });
        }

        var initVideoSrcLoad = function () {
            if (Modernizr.mq('(max-width: 767px)')) {
                $videoSrc.attr('src', portrait);
                $video.load();
            } else if (Modernizr.mq('(min-width: 768px)')) {
                $videoSrc.attr('src', landscape);
                $video.load();
            }
        }

        $(window).on('resize orientationchange', $.debounce(200, function () {
            initVideoSrcLoad();
        }));

        initVideoSrcLoad();

        togglePlay();
    });
})(jQuery);