//gallery video js
(function ($) {
    // Helper method to add thumbnail image to a $container
    function addThumbnail($container, thumbnailURL) {
        var $thumb = $(THUMBNAIL_TEMPLATE(thumbnailURL));
        $thumb.on('load', function () {
            $container.find(selectors.needsThumbnail).remove();
            $container.prepend(this);
        });
    };

    // method to build thumbnail / preview image markup from url
    function THUMBNAIL_TEMPLATE(thumbnailURL) {
        return '<img class="video-image" src="' + thumbnailURL + '" alt="" />';
    };

    // method to build video markup from url
    function VIDEO_TEMPLATE(videoURL) {
        return [
            '<div class="video-content">',
            '<iframe src="' + videoURL + '" class="video-source" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>',
            '</div>'
        ].join('');
    };

    var selectors = {
        videoCode: 'video-code',
        videoType: 'video-type',
        videoComponent: '.video-component',
        needsThumbnail: '.js-video-needs-thumbnail'
    };

    // Provider definitions
    var providers = {
        'youtube': {
            loadThumbnail: function ($container, videoCode) {
                addThumbnail($container, '//img.youtube.com/vi/' + videoCode + '/maxresdefault.jpg');
            },
            getVideoUrl: function (videoCode) {
                return 'https://www.youtube.com/embed/' + videoCode + '?rel=0&autoplay=1&disablekb=1&iv_load_policy=3&modestbranding=1&showinfo=0';
            }
        },
        'vimeo': {
            loadThumbnail: function ($container, videoCode) {
                $.ajax({
                    type: 'GET',
                    url: '//vimeo.com/api/v2/video/' + videoCode + '.json',
                    jsonp: 'callback',
                    dataType: 'jsonp',
                    success: function (data) {
                        var imageUrl = data[0].thumbnail_large.split('_')[0] + '_720.jpg';
                        addThumbnail($container, imageUrl);
                    }
                });
            },
            getVideoUrl: function (videoCode) {
                return 'https://player.vimeo.com/video/' + videoCode + '?autoplay=1&portrait=0';
            }
        }
    };

    $.fn.evoVideo = function () {
        return $(this).not('.loaded').addClass('loaded').each(function () {
            var $this = $(this),
                $container = $this.closest(selectors.videoComponent),
                videoCode = $container.data(selectors.videoCode),
                provider = providers[$container.data(selectors.videoType)];

            if (!provider)
                return;

            $container.on('click', function () {
                var videoURL = provider.getVideoUrl(videoCode);
                $container.append(VIDEO_TEMPLATE(videoURL));
                $container.addClass('playing');
            });

            if ($this.find(selectors.needsThumbnail).length)
                provider.loadThumbnail($container, videoCode);
        });
    };

    $(selectors.videoComponent).evoVideo();
})(jQuery);