(function ($) {
    $(document).ready(function() {
	    $('.card-collection-slider').each(function(){
	        var $slider = $(this);
	        var $progressBar = $slider.next('.js-slick-progress-bar');
	        var $progressBarLabel = $progressBar.children('.progress-label');

	        $slider.on('init', function(event, slick) {   
	            var calc = ( 1 / (slick.slideCount) ) * 100;
	            
	            $progressBar.css('background-size', calc + '% 100%').attr('aria-valuenow', calc );
	            
	            $progressBarLabel.text( calc + '% completed' );
	        });

	        $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {   
	            var calc = ( (nextSlide + 1 ) / (slick.slideCount) ) * 100;
	            
	            $progressBar.css('background-size', calc + '% 100%').attr('aria-valuenow', calc );
	            
	            $progressBarLabel.text( calc + '% completed' );
	        });

	        if($slider.hasClass('card-orientation-landscape')) {
	            var slidesToShowLg = 2;
	            var slidesToShowMd = 2;
	        } else {
	            var slidesToShowLg = 4;
	            var slidesToShowMd = 3;
	        }

	        $slider.slick({
	            slidesToShow: slidesToShowLg,
	            slidesToScroll: 1,
	            dots: false,
	            arrows: true,
	            autoplay: false,
	            infinite: false,
	            rows: 0,
	            responsive: [
	                {
	                    breakpoint: 1150,
	                    settings: {
	                        slidesToShow: slidesToShowMd,
	                    }
	                },
	                {
	                    breakpoint: 950,
	                    settings: {
	                        slidesToShow: 2,
	                    }
	                },
	                {
	                    breakpoint: 650,
	                    settings: {
	                        slidesToShow: 1,
	                        arrows: false
	                    }
	                }
	            ]
	        });

	        var cardImage = $slider.find('.card-collection-item .image');

	        $(window).on('load resize', function () {
	            var cardImageHeight = cardImage.outerHeight();
	            $slider.find('.slick-next, .slick-prev').css("top", parseInt(cardImageHeight / 2));
	        });
	    });
	});
})(jQuery);