//global accordion UI

(function ($) {
    //Code for resuable accordion component
    var accordionToggleInit = function () {
        $('.accordion-head').on("click", function () {
            if ($(this).hasClass("accordion-mobile")) {
                if (evo.windowWidth < evo.mq.screenSmMin) {
                    var $accordionHead = $(this),
                        $accordionBody = $accordionHead.next('.accordion-body');

                    if ($accordionHead.parent().hasClass("accordion-group")) {
                        $accordionHead.siblings().each(function () {
                            if ($(this).hasClass("expanded")) {
                                $(this).removeClass("expanded").next().slideToggle();
                            }
                        });
                    }

                    $accordionHead.toggleClass("expanded");
                    $accordionBody.slideToggle();
                }
            }
            else {
                var $accordionHead = $(this),
                    $accordionBody = $accordionHead.next('.accordion-body');

                if ($accordionHead.parent().hasClass("accordion-group-container")) {
                    $accordionHead.siblings().each(function () {
                        if ($(this).hasClass("expanded")) {
                            $(this).removeClass("expanded").next().slideToggle();
                        }
                    });
                }

                $accordionHead.toggleClass("expanded");
                $accordionBody.slideToggle();
            }
        });

        //Check the accordion state on page load, and expand accordiongly
        $('.accordion-head').each(function () {
            var $this = $(this);

            if ($this.hasClass("expanded")) {
                $this.next(".accordion-body").slideToggle();
            }
        });
    };

    accordionToggleInit();
})(jQuery);