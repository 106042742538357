(function ($) {
    $(document).ready(function() {
    	$('.js-inline-video').click(function(event){
    		event.preventDefault();
            $(this).addClass('video-active');
            var $currentVideo = $(this).attr("data-src");
            $(this).find('iframe').attr('src', $currentVideo);
    	});

    	$('.video-series').each(function(){
    		var $slider = $(this);
    		var $sliderNav = $(this).prev('.navbar-carousel').find('.navbar-navigation ul');

    		$slider.on('afterChange', function(event, slick, currentSlide, nextSlide) { 
	            $sliderNav.children().removeClass('current-item');
	            $sliderNav.children().find('.animated-icon').removeClass('animate');
	            $sliderNav.children().eq(currentSlide).addClass('current-item');
	            $sliderNav.children().eq(currentSlide).find('.animated-icon').addClass('animate');
	        });

    		$slider.slick({
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            dots: false,
	            arrows: false,
	            autoplay: false,
	            infinite: false,
	            fade: true,
	            rows: 0
	        });

	        $sliderNav.children('li').find('a').click(function(event) {
	        	event.preventDefault();
	        	slideIndex = $(this).parent().prevAll().length;
	        	$slider.slick('slickGoTo', parseInt(slideIndex));
	        });
    	});
	});
})(jQuery);