/*
 * File that manages product thumbs and the UI interactions that can occur with them
 */
(function ($) {
    var selectors = {
        thumb: '.js-product-thumb',
        thumbImage: '.js-product-thumb-image',
        thumbLink: '.js-product-thumb-details-link',
        thumbSwatch: '.js-product-thumb-swatch'
    };

    //event for image swatch clicks
    //updates the product thumb image to match the swatch selected
    evo.$.body.on('click', selectors.thumbSwatch, function () {
        var $swatch = $(this),
            $image = $swatch.closest(selectors.thumb).find(selectors.thumbImage);

        $image.attr('src', $swatch.data('image-url'));
    });

    //event for product thumb clicks that appends an image hash parameter
    //for the image currently viewed by the user
    //this signals the pdp to ensure that that image is the one they see on page load
    evo.$.body.on('click', selectors.thumbLink, function () {
        var $this = $(this),
            imgPath = $this.find(selectors.thumbImage).attr('src');
        if (!imgPath) {
            return;
        }

        //we only need to pass the part of the image path that is after the specific size in the path
        //ex. '//images.evo.com/imgp/250/70792/392509/dragon-nfx-goggles-rasta-red-ionized-yellow-blue-ionized.jpg'
        //becomes '70792/392509/dragon-nfx-goggles-rasta-red-ionized-yellow-blue-ionized.jpg'
        //note: this will not match mashup images, but we don't need to handle for those cases
        var imagePathMatch = imgPath.match(/\d+\/\d+\/[^\/]*$/);
        if (imagePathMatch) {
            $this.attr('href', $this.attr('href') + '#image=' + imagePathMatch[0]);
        }
    });
})(jQuery);