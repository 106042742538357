(function ($) {
    $(document).ready(function() {
	    $('.card-grid-container').each(function(){
	        var $slider = $(this);
	        var $progressBar = $slider.next('.js-slick-progress-bar');
	        var $progressBarLabel = $progressBar.children('.progress-label');

	        $slider.on('init', function(event, slick) {   
	            var calc = ( 1 / (slick.slideCount) ) * 100;
	            
	            $progressBar.css('background-size', calc + '% 100%').attr('aria-valuenow', calc );
	            
	            $progressBarLabel.text( calc + '% completed' );
	        });

	        $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {   
	            var calc = ( (nextSlide + 1 ) / (slick.slideCount) ) * 100;
	            
	            $progressBar.css('background-size', calc + '% 100%').attr('aria-valuenow', calc );
	            
	            $progressBarLabel.text( calc + '% completed' );
	        });

	        $(window).on('load resize', $.debounce(200, function () {
	            if (Modernizr.mq('(min-width: 1024px)')) {
	                $slider.slick('unslick');
	            } else {
	            	$slider.slick({
			            slidesToShow: 2,
			            slidesToScroll: 1,
			            dots: false,
			            arrows: false,
			            autoplay: false,
			            infinite: false,
			            rows: 0,
			            responsive: [
					        {
					            breakpoint: 600,
					            settings: {
					            	slidesToShow: 1,
					            }
					        }
					    ]
			        });
	            }
	        }));
	    });
	});
})(jQuery);