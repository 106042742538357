(function ($) {
    $(window).load(function() {
	    if($('.animated-icon').length) {
	    	
	    	// Animate SVGs on scroll
	    	var animatedSVGs = document.getElementsByClassName('animated-icon');

			Array.prototype.forEach.call(animatedSVGs, function(el, index, array){
				var waypoint = new Waypoint({
				    element: el,
				    handler: function(direction) {
				    	if ($(el).parent().hasClass("current-item") || !$(el).hasClass("navbar-icon")) {
					    	$(el).addClass('animate');
					    }
				    },
				    offset: '60%'
				});
			});

			// Navbar hover animations
			$('.navbar-item').mouseenter(function(){
				if(!$(this).hasClass('current-item')) {
					$(this).children('.navbar-icon').addClass('animate');
				}
			});

			$('.navbar-item').mouseleave(function(){
				if(!$(this).hasClass('current-item')) {
			  		$(this).children('.navbar-icon').removeClass('animate');
			  	}
			});
			

    		$(window).scroll(function() {
    			if ($(document).scrollTop() > 20) {
    				Waypoint.refreshAll();
    			}
    		});
    	}
	});
})(jQuery);