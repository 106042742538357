(function ($) {
    $(document).ready(function() {
	    // PIE CHART
	    var pieChartElement = document.getElementsByClassName('pie-chart');

		Array.prototype.forEach.call(pieChartElement, function(el, index, array){
			var waypoint = new Waypoint({
			    element: el,
			    handler: function(direction) {
			      	createPieChart($(el));
			    },
			    offset: 'bottom-in-view'
			});
		});

		function createPieChart(element) {
			var ctx = element;
			var pieChartLegend = $(element).parents('.pie-chart-container').find('.pie-chart-legend');
			  
			var chartData = [];
			$(pieChartLegend).children().find('span').each(function(){
			    chartData.push(Number($(this).html()));
			});
			  
			var colors = [];
			$(pieChartLegend).children().each(function(i){
			    colors.push($(this).attr('data-color'));
			    
			    $(this).find('i').css("background-color", colors[i]);
			});
			  
			var sectionBackgroundColor = $(element).parents('.impact-statistics').css("background-color");

			Chart.defaults.global.tooltips.custom = function(tooltip) {
			    // Tooltip Element
			    var tooltipEl = this._chart.canvas.nextElementSibling;

			    // Hide if no tooltip
			    if (tooltip.opacity === 0) {
			      	tooltipEl.style.opacity = 0;
			      	return;
			    }

			    // Set Text
			    if (tooltip.body) {
			      	var total = 0;

			      	// get the value of the datapoint
			      	var value = this._data.datasets[tooltip.dataPoints[0].datasetIndex].data[tooltip.dataPoints[0].index].toLocaleString();

			      	// calculate value of all datapoints
			      	this._data.datasets[tooltip.dataPoints[0].datasetIndex].data.forEach(function(e) {
			        	total += e;
			      	});

			      	// calculate percentage and set tooltip value
			      	tooltipEl.innerHTML = (value / total * 100) + '<sup>%</sup>';
			    }

			    // Display, position, and set styles for font
			    tooltipEl.style.opacity = 1;
			};

			var myChart = new Chart(ctx, {
			    type: 'doughnut',
			    data: {
			        datasets: [{
			            data: chartData,
			            backgroundColor: colors,
			            borderColor: sectionBackgroundColor,
			            borderWidth: 2,
			            hoverBackgroundColor: colors,
			            hoverBorderColor: sectionBackgroundColor
			        }]
			    },
			    options: {
			        responsive: true,
			        cutoutPercentage: 60,
			        tooltips: {
			          	enabled: false,
			        },
			    }
			});
		}

		// NUMBERS COUNT UP

		var statisticCounts = document.getElementsByClassName('statistic-count');

		Array.prototype.forEach.call(statisticCounts, function(el, index, array){
			var waypoint = new Waypoint({
			    element: el,
			    handler: function(direction) {
			      	var $this = $(el);
			      	var counterStart = $this.data('start');
			      	var counterEnd = $this.data('end');

			      	$(el).parent().addClass('fade-in');
			      
			      	if(counterEnd > 9) {
			        	var duration = 3000;
			      	} else {
			        	var duration = 1000;
			      	}
			      
			      	var odometer = new Odometer({ 
			        	el: $(el)[0], 
			        	value: counterStart, 
			        	theme: 'default',
			        	duration: duration
			      	});
			      	odometer.render();

			      	$(el).html(counterEnd);
			    },
			    offset: 'bottom-in-view'
			});
		});

		$(window).scroll(function() {
			if ($(document).scrollTop() > 20) {
				Waypoint.refreshAll();
			}
		});
	});
})(jQuery);